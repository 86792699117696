import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import appIcons from '../images/app-icons.svg';
import splash from '../images/Splashv2.png';

const IndexPage = () => {
  const breakpoints = useBreakpoint();

  return (
    <section style={{ marginTop: 15 }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Moneycl App</title>
        <link rel="canonical" href="https://moneycl.app" />
      </Helmet>
      {!breakpoints.isMobile && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              fontWeight: 'bold',
              flexBasis: '30%',
              maxWidth: 485,
              textAlign: 'center',
            }}
          >
          <h1 style={{
            fontSize: breakpoints.isSmallTablet
            ? 30
            : breakpoints.isTablet
            ? 36
            : 45,
            margin: 0,
          }}>
            Moneycl is a simple finance tracking and planning app coming soon
            to both Android and iOS.
          </h1>
            <img
              alt="Moneycl App Store Links"
              style={{ marginTop: 50, width: '80%', opacity: 0.5 }}
              src={appIcons}
            />
            <span
              style={{
                textAlign: 'center',
                fontSize: 16,
                display: 'block',
              }}
            >
              Google Play and App Store links will be available soon.
            </span>
          </span>
          <img
            alt="Moneycl Sample Screens"
            style={{
              flexBasis: '50%',
              objectFit: 'contain',
              width: '60%',
              maxWidth: 1000,
            }}
            src={splash}
          />
        </div>
      )}
      {breakpoints.isMobile && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <img
            alt="Moneycl Sample Screens"
            style={{
              objectFit: 'contain',
              maxWidth: '80%',
              padding: 20,
              display: 'flex',
              justifyContent: 'center',
            }}
            src={splash}
          />
          <span
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            <h1 style={{
              fontSize: 30,
              margin: 0,
            }}>
              Moneycl is a simple finance tracking and planning app coming soon
              to both Android and iOS.
            </h1>
            <img
              alt="Moneycl App Store Links"
              style={{
                marginTop: 20,
                width: '70%',
                paddingLeft: '15%',
                paddingRight: '15%',
                opacity: 0.5,
                marginBottom: 10,
              }}
              src={appIcons}
            />
            <span
              style={{
                textAlign: 'center',
                fontSize: 12,
                display: 'block',
              }}
            >
              Google Play and App Store links will be available soon.
            </span>
          </span>
        </div>
      )}
    </section>
  );
};

export default IndexPage;
